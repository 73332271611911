import React from "react"
import Seo from "../components/seo"
import termsPdf from '../pdfs/Brico_Website Privacy Policy v1.0.pdf'
const PrivacyPolicy = () => {

    return (
        <>
         <Seo title="Privacy policy" description="Privacy policy for Evistamp" />
      <div>
        <iframe className="w-full h-screen" src={termsPdf}/>
        </div>
        </>
    )
}
export default PrivacyPolicy